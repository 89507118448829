@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap");

body {
  background: #392e4d;
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat, Roboto, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

header {
  background-color: #23153d;
  height: 4.5em;
  border-bottom: 3px solid #eeb81e;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

header img {
  margin: 11px;
  height: 3em;
}

header > div {
  flex: 1;
}

div.header-menu-items {
  display: flex;
  flex: 3;
  justify-content: space-evenly;
}
.horse-background {
  background-image: url("assets/CreastOnHorses.jpg");
  background-position: center;
  background-size: cover;
  height: 1000px;
  width: 100%;
}

.football-background {
  background-image: url("assets/LogoOnFootballGame.jpg");
  background-position: center;
  background-size: cover;
  height: 800px;
  width: 100%;
  text-align: center;
  color: #eeb81e;
  font-size: 4em;
  font-weight: bold;
  padding-top: 300px;
}

footer {
  background-color: #23153d;
  border-top: 2px solid #eeb81e;
  border-bottom: 2px solid #eeb81e;
  padding-top: 30px;
  padding-bottom: 30px;
}

a {
  color: #e7e7e7;
  text-decoration: none;
}

.parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}

.div2 {
  grid-area: 1 / 3 / 2 / 4;
}

.div1,
.div2 {
  opacity: 0.9;
  margin: 80px 0 0 40px;
  padding: 0 20px;
  background-color: #fff;
  color: #000000;
  border: #23153d solid 1px;
}

.parent > h2 {
  color: #000000;
}

div.main {
  width: 80%;
  margin: auto;
}
